import React from 'react';
import { gsap } from 'gsap';

// Pieces
import {
  Heading,
  GridWrapper,
  Section,
  Input,
  Label,
  Box,
  Paragraph,
  FlexWrapper
} from '@thepuzzlers/pieces';

// Local components
import { PrimaryButton } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

// formStates
const WAITING_FOR_SUBMIT = 'waiting';
const SUBMITTING = 'submitting';
const ERROR = 'error';
const SUCCESS = 'success';

export const Form = ({ data }) => {
  const [formState, setFormState] = React.useState(WAITING_FOR_SUBMIT);

  const handleServerResponse = (ok, form) => {
    if (ok) {
      setFormState(SUCCESS);
      // Only rest form, if sending was successful
      form.reset();
    } else {
      setFormState(ERROR);
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    setFormState(SUBMITTING);
    const form = e.target;
    const formData = new FormData(form);

    fetch('https://getform.io/f/7507cb50-9388-4090-a24e-24a4ca55fb6d', {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        // show loader a bit longer to avoid loading flash
        setTimeout(() => {
          if (response.ok) {
            handleServerResponse(true, form);
          } else {
            handleServerResponse(false, form);
          }
        }, 400);
      })
      .catch(() => {
        setTimeout(() => {
          handleServerResponse(false, form);
        }, 400);
      });
  };

  return (
    <Section>
      <RequestForm
        onSubmit={handleOnSubmit}
        data={data}
        formState={formState}
      />
    </Section>
  );
};

const RequestForm = ({
  data: {
    button_text,
    description,
    headline,
    message_error_description,
    message_error_title,
    message_success_description,
    message_success_title,
    placeholder_email,
    placeholder_name,
    placeholder_phone,
    placeholder_request,
    privacy_disclaimer
  },
  onSubmit,
  formState
}) => {
  const messages = {
    success: {
      headline: message_success_title,
      text: message_success_description
    },
    error: {
      headline: message_error_title,
      text: convertApiHtmlText(message_error_description.html)
    }
  };

  return (
    <GridWrapper
      onSubmit={onSubmit}
      variant="inside.autoColumns"
      as="form"
      sx={{
        gridColumn: [
          '1/span 12',
          '2/span 10',
          '2/span 22',
          '2/span 22',
          '3/span 20',
          '4/span 18'
        ]
      }}>
      {/* Form Headline */}
      <Box
        sx={{
          gridColumn: [
            '1/span 12',
            '1/span 10',
            '1/span 18',
            '1/span 20',
            '1/span 14',
            '1/span 12'
          ]
        }}>
        <Heading
          as="h4"
          sx={{
            fontFamily: 'primary.bold',
            lineHeight: [1.5, 1.5, 1.6, 1.6, 1.6, 1.6],
            fontSize: ['2.2rem', '3rem', '3rem', '2.2rem', '3rem', '3.6rem']
          }}>
          {headline}
        </Heading>
        <Paragraph
          sx={{
            paddingTop: '1rem',
            fontFamily: 'body.normal',
            lineHeight: 1.75,
            fontSize: [
              '1.6rem',
              '1.7rem',
              '1.7rem',
              '1.5rem',
              '1.7rem',
              '1.6rem'
            ]
          }}>
          {description}
        </Paragraph>
      </Box>
      {/* Name Field */}
      <TextField
        sx={{
          gridColumnEnd: ['span 12', 'span 10', 'span 11', 'span 10', 'span 8']
        }}
        name="name"
        required
        placeholder={placeholder_name}
      />
      {/* Email Field */}
      <TextField
        sx={{
          gridColumnStart: ['1', '1', '1', '1', '1', '1'],
          gridColumnEnd: ['span 12', 'span 10', 'span 11', 'span 10', 'span 8']
        }}
        name="email"
        type="email"
        required
        placeholder={placeholder_email}
      />
      {/* Phone Field */}
      <TextField
        sx={{
          gridColumnStart: ['1', '1', '13', '12', '10', '10'],
          gridColumnEnd: ['span 12', 'span 10', 'span 11', 'span 11', 'span 8']
        }}
        name="phone"
        type="text"
        placeholder={placeholder_phone}
      />
      <Input name="spam_check" type="hidden" />
      <Textarea placeholder={placeholder_request} />
      {messages[formState] && (
        <SuccessAndErrorMessage message={messages[formState]} />
      )}
      <SubmitButton text={button_text} formState={formState} />
      <PrivacyDisclaimer text={privacy_disclaimer} />
    </GridWrapper>
  );
};

const SuccessAndErrorMessage = ({ message }) => {
  const lineRef = React.useRef();
  const textRef = React.useRef();

  React.useLayoutEffect(() => {
    gsap.set(textRef.current, {
      autoAlpha: 0,
      height: 0
    });
  }, []);

  React.useEffect(() => {
    gsap.to(textRef.current, {
      autoAlpha: 1,
      height: '100%'
    });
    gsap.fromTo(
      lineRef.current,
      {
        height: 0
      },
      {
        height: '100%',
        delay: 0.1
      }
    );
  }, []);

  return (
    <FlexWrapper
      sx={{
        gridColumnEnd: [
          'span 12',
          '/span 10',
          '/span 22',
          '/span 22',
          '/span 20',
          '/span 18'
        ],
        marginTop: ['1.5rem', '2rem', '2rem', '2rem', '2rem', '2.5rem']
      }}>
      <Box
        className="line"
        ref={lineRef}
        sx={{
          width: [2, 3, 4, 2, 4, 5],
          height: 'auto',
          bg: 'secondary',
          marginRight: 10
        }}
      />
      <Box ref={textRef} sx={{ overflow: 'hidden', height: '100%' }}>
        <Paragraph
          sx={{
            lineHeight: [1.5, 1.5, 1.5, 1.5, 1.5, 1.5],
            fontFamily: 'primary.bold',
            fontSize: [
              '1.6rem',
              '1.8rem',
              '1.8rem',
              '1.6rem',
              '1.8rem',
              '1.8rem'
            ]
          }}>
          {message.headline}
        </Paragraph>
        <Paragraph
          sx={{
            fontSize: [
              '1.5rem',
              '1.6rem',
              '1.6rem',
              '1.4rem',
              '1.6rem',
              '1.6rem'
            ],

            fontFamily: 'body.normal',
            lineHeight: [1.5, 1.5, 1.5, 1.5, 1.5, 1.5],
            '& > a': {
              textDecoration: 'underline',
              color: 'text'
            },
            '& > a:hover': {
              opacity: 1,
              color: 'black'
            }
          }}
          dangerouslySetInnerHTML={{ __html: message.text }}
        />
      </Box>
    </FlexWrapper>
  );
};

const TextField = ({
  name,
  placeholder,
  required,
  type = 'text',
  sx = {},
  ...props
}) => (
  <Box
    className="text-field"
    sx={{
      marginTop: ['4rem', '5.6rem', '6.1rem', '4rem', '4rem', '4rem'],
      gridColumnStart: 1,
      ...sx
    }}>
    {/* Hidden Label - just for screen readers */}
    <Label
      className="screen-reader-text"
      htmlFor="name"
      sx={{
        display: 'none'
      }}>
      {placeholder}
    </Label>
    <Input
      name={name}
      placeholder={placeholder}
      type={type}
      required={required}
      {...props}
    />
  </Box>
);

const Textarea = ({ placeholder }) => (
  <TextField
    name="anfrage"
    as="textarea"
    required
    rows="5"
    placeholder={placeholder}
    sx={{
      marginTop: ['4rem', '5.6rem', '6.1rem', '4rem', '5.4rem', '6.4rem'],
      gridColumnEnd: ['span 12', 'span 10', 'span 18', 'span 16', 'span 13']
    }}
  />
);

const PrivacyDisclaimer = ({ text }) => (
  <Paragraph
    sx={{
      fontSize: ['1.5rem', '1.6rem', '1.6rem', '1.4rem', '1.6rem', '1.6rem'],
      fontFamily: 'body.normal',
      lineHeight: 1.75,
      gridRow: ['auto', 'auto', 'auto', '5', '5', '5'],
      opacity: 0.6,
      gridColumn: [
        '1/span 11',
        '1/span 10',
        '1/span 18',
        '8/ span 14',
        '7/ span 12',
        '6/ span 13'
      ],
      marginTop: ['2.4rem', '2.4rem', '2.4rem', '4.8rem', '8rem', '8rem'],
      '&>a': {
        textDecoration: 'underline',
        color: 'text'
      },
      '&>a:hover': {
        opacity: 1,
        color: 'black'
      }
    }}
    dangerouslySetInnerHTML={{ __html: convertApiHtmlText(text.html) }}
  />
);

const SubmitButton = ({ text, formState }) => (
  <PrimaryButton
    type="submit"
    disabled={formState === SUBMITTING}
    sx={{
      gridRow: ['auto', 'auto', 'auto', '5', '5', '5'],
      gridColumn: [
        '1/span 12',
        '1/span 10',
        '1/span 16',
        '1/ span 7',
        '1/ span 7',
        '1/ span 5'
      ],
      marginRight: 'auto',
      marginBottom: 'auto',
      marginTop: ['6.4rem', '6.4rem', '8rem', '4.8rem', '8rem', '8rem'],
      position: 'relative'
    }}>
    <Box
      as="span"
      sx={{ visibility: formState === SUBMITTING ? 'hidden' : 'visible' }}>
      {text}
    </Box>
    {formState === SUBMITTING && <SvgLoaderIcon />}
  </PrimaryButton>
);

function SvgLoaderIcon(props) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
      }}>
      <svg
        fill="none"
        height="1em"
        viewBox="0 0 18 18"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          clipRule="evenodd"
          d="M8.166 1.5a.833.833 0 111.667 0v1.667a.833.833 0 11-1.667 0V1.5zm6.667 6.667H16.5a.833.833 0 110 1.666h-1.667a.833.833 0 110-1.666zM4 9a.833.833 0 00-.833-.833H1.5a.833.833 0 100 1.666h1.667C3.627 9.833 4 9.461 4 9zm-.995-5.789a.834.834 0 011.179-.02l1.199 1.158a.833.833 0 01-1.158 1.199l-1.2-1.159a.833.833 0 01-.02-1.178zm10.19 2.57a.834.834 0 00.58-.233l1.2-1.158a.833.833 0 00-1.16-1.2l-1.198 1.158a.833.833 0 00.579 1.433zm-5.029 9.052a.833.833 0 111.667 0V16.5a.833.833 0 11-1.667 0v-1.667zm5.609-2.38a.835.835 0 00-1.159 1.198l1.2 1.159a.83.83 0 00.579.233.833.833 0 00.58-1.432l-1.2-1.159zM3.025 13.61l1.2-1.159a.835.835 0 011.158 1.2L4.184 14.81a.83.83 0 01-.58.233.833.833 0 01-.58-1.432z"
          fill="currentColor"
          fillRule="evenodd">
          <animateTransform
            attributeName="transform"
            begin="0s"
            dur="2s"
            from="0 9 9"
            repeatCount="indefinite"
            to="360 9 9"
            type="rotate"
          />
        </path>
      </svg>
    </Box>
  );
}
